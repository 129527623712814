// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import React from "react";
export const configJSON = require("../../config");
import { Message } from "../../../../../framework/src/Message";
import { openNotification } from "components/src/Notification.web";

export interface Props {
  data: any;
  currentTrack:any;
  selectedTrack:(item:any)=>void
}

interface PlaylistItemArray{
      "id": string,
      "type": string,
      "attributes": {
        "title": string,
        "user_type": string,
        "track_file": string,
        "video_file": string,
        "art_work": string,
        "artist_name": string,
        "artist_image": string,
        "play_count": number,
        "comments_count": number,
        "repost_count": number,
        "last_twenty_four_hours_percentage": number,
        "last_seven_days_percentage": number,
        "last_seven_days_graph": {
          "Sunday": number,
          "Monday": number,
          "Tuesday": number,
          "Wednesday": number,
          "Thursday": number,
          "Friday": number,
          "Saturday": number
        },
        "artist_id": number,
        "user_follow": boolean
      }
}

interface S {
  repostCount: any;
  playVideoFromRedirection: boolean;
  topVideoObj: any;
  currentKey: any;
  selectedSingleVideo: any;
  trackId: any;
  openFilter: boolean;
  graphdata: any;
  graphCategory: any;
  openRepostPopup: boolean;
  textFieldValue: any;
  openModal: boolean;
  openAlertLogin: boolean;
  dialogTextAlertLogin: any;
  OpenScanPopup: boolean;
  userTrack:any;
}

interface SS {
  id: any;
}

export default class PlaylistVideoController extends BlockComponent<
  Props,
  S,
  SS
> {
  videosPlayRef: any;
  apiVideoList: string = "";
  apiFilterTrack: string = "";
  apiFetchRepost: string = "";
  apiChartTrackRepost: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.videosPlayRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];
    this.state = {
      repostCount: 0,
      playVideoFromRedirection: true,
      topVideoObj: [],
      currentKey: null,
      selectedSingleVideo: localStorage.getItem("selectedSingleVideo"),
      trackId: "0",
      openFilter: false,
      graphdata: null,
      graphCategory: null,
      openRepostPopup: false,
      textFieldValue: "",
      openModal: false,
      openAlertLogin: false,
      dialogTextAlertLogin: false,
      OpenScanPopup: false,
      userTrack:[]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setPropsData();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.data !== this.props.data) {
      this.setPropsData();
    }
  }

  setPropsData = () => {
    this.setState({
      topVideoObj: this.props.data.attributes,
      trackId: this.props.data.id,
      repostCount: this.props.data.attributes?.repost_count,
    });

    if (this.props.data?.attributes?.video_list) {
      this.setState({ playVideoFromRedirection: true });
    }
  };

  handleCaptionChange = (e: any) => {
    this.setState({
      textFieldValue: e.target.value,
    });
  };

  repostSubmit = async () => {
    this.handleChartTrackRepost(this.state.textFieldValue, this.state.trackId);
    await this.handlerFetchRepost();
    this.handleRepostClose();
  };

   findNextObject = (currentObj:any) => {
    if(currentObj.video_file !== this.state.topVideoObj){
    let playlistListItem=this.props.currentTrack.attributes.playlist_items.data
    const index = playlistListItem.findIndex((item:PlaylistItemArray) => item.attributes.title === currentObj.title);
    if (index !== -1 && index + 1 < playlistListItem.length) {
      return this.props.selectedTrack(playlistListItem[index + 1])
    }
    return null;
  }else{
    return null
  }
  };

  handleMouseMoveVal = () => {
    if (this.videosPlayRef.current) {
      this.videosPlayRef.current.style.visibility = 'visible';
    }
  };

  hanldeMouseLeaveval = () => {
    if (this.videosPlayRef.current) {

    this.videosPlayRef.current.style.visibility = "hidden";
    }
  };
  handleRepostClose = () => {
    this.setState({ openRepostPopup: false });
  };

  setVideoCount = async (id: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiVideoList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `/bx_block_audiomusic/play_track?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFilterTrack = async (id: any) => {
    
    this.setState({ openFilter: false });
   
  };
  onShare = () => {
    const userType = localStorage.getItem("user_type");

    if (userType == "listener") {
      this.setState({
        openAlertLogin: true,
        dialogTextAlertLogin: "3",
      });
    } else {
      this.setState({
        OpenScanPopup: true,
      });
    }
  };


  handleChartTrackRepost = async (caption: any, trackId: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChartTrackRepost = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/repost?id=${trackId}&caption=${caption}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handlerFetchRepost = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchRepost = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/bx_block_audiomusic/expan_track?id=${this.props.data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChartTrack = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification("Successfully Reposted", "Success");
    } else {
      openNotification("Some error occurred", "Error");
    }
  };

  handleFilterTrack = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        graphdata: Object.values(responseJson.data),
        graphCategory: Object.values(responseJson.data),
      });
    }
  };

  handleFetchTrack = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      this.setState({
        repostCount: responseJson.data.attributes.repost_count,
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apiVideoList) {
      return
    } else if (apiRequestCallId == this.apiFilterTrack) {
      this.handleFilterTrack(responseJson);
    } else if (apiRequestCallId == this.apiFetchRepost) {
      this.handleFetchTrack(responseJson);
    } else if (apiRequestCallId == this.apiChartTrackRepost) {
      this.handleChartTrack(responseJson);
    }

    // Customizable Area End
  }
}

// Customizable Area End
