// Customizable Area Start
import { Box, Typography } from "@material-ui/core";
import React from "react";
import {styled } from "@material-ui/styles";
import TrackDetails from "../TrackDetails.web";
import RightSideTrackController from "./RightSideTrackController";

const TracksboxRightsidecontainer = styled(Box)({
  width: "47%",
  height: "50vh",
  alignItems: "center",
  gap: "5%",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: " 5%",
    gap: "20px",

  },
});
const TrackBoxfordtails = styled(Box)({
  width: "47%",
  // height: "50vh",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  gap: "15px",
  "@media(max-width: 1024px)": {
    width: "auto",
    height: "auto",
    marginTop: " 5%",
    gap: "20px",

  },
});

const TracksImage = styled(Box)({
  height: "148px",
  width: "148px",
  // width: "148px",
  // height: "148px",
  // width: "148px",
  borderRadius: "12px",
  "@media(max-width: 600)": {
    width: "100%",
    // height:"auto",
    // marginTop:" 22%",
    // gap:"20px"
  },
});

const TracksboxRightside = styled(Box)({
  width: "fit-content",
  // height: "50vh",
  display: "flex",
  minWidth:"100%",
  flexDirection: "column" as "column",
  gap: "15px",
  "@media(max-width: 1024px)": {
    width:"auto",
    // height:"auto",
    // marginTop:" 22%",
    // gap:"20px"
  },
});
const RightsideAlltracksboxwrapper = styled(Box)({
  overflowY: "auto",
  maxHeight: "810px",
  display: "grid",
  scrollbarWidth: "none",
  // flexWrap: "",
  gridTemplateColumns: "repeat(4, 1fr)",
  width: "fit-content",
  gap: "25px",
  "@media(max-width: 1700px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media(max-width: 1550px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 1300px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media(max-width: 1024px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(4, 1fr)",
  },

  "@media(max-width: 768px)": {
    // justifyContent:"center" ,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media(max-width: 450px)": {
    // justifyContent:"center",
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "&::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});



export default class RightSideTrackDetails extends RightSideTrackController {
  render() {
    const {
      topPlayMusic,
      top40trackList,
      showDetails,
      trackDetailsId,
      getAllSplits,
      index,
    } = this.props;
    return (
      <>

        {showDetails ? (
          <TrackBoxfordtails>
            <TrackDetails
              navigation={undefined}
              id={""}
              trackDetails={trackDetailsId}
              updateData={() => getAllSplits(index)}
              topPlayMusic={topPlayMusic}
              index={index}
              setShowDetails={this.props.setShowDetails}

            />
          </TrackBoxfordtails>

        ) : (
          <>
            {top40trackList?.length > 0 ? (
              <TracksboxRightsidecontainer>
                <TracksboxRightside>
                  {" "}
                  <Box
                    style={{ display: " flex ", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Typography style={webStyle.trackname}>
                      <span style={webStyle.bluetext}>
                        Top {this.props.top40trackList.length < 40 ? this.props.top40trackList.length : 40}
                        </span> {index}
                    </Typography>
                    <Typography style={webStyle.splittext}>This week</Typography>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      // display: "flex",
                      // justifyContent: "center",
                    }}
                  >

                    <RightsideAlltracksboxwrapper>
                      {top40trackList.map((item: any, index: any) => (
                        <Box
                          key={index}
                          style={{ height: "100%", cursor: "pointer" }}
                        >
                          <img
                            src={item.attributes.art_work}
                            className="tracks_image"
                            style={{objectFit:"cover"}}
                            onClick={() => {
                              topPlayMusic(item, index, top40trackList, "song");
                            }}
                          />

                          <Typography style={webStyle.audioimagetext}>
                            {index + 1}. {item.attributes.title}
                          </Typography>

                          <Typography style={webStyle.audioartistext}>
                            {item.attributes.user_role} 
                          </Typography>
                        </Box>
                      ))}
                    </RightsideAlltracksboxwrapper>
                  </Box>
                </TracksboxRightside>
              </TracksboxRightsidecontainer>

            ) : (
              <TracksboxRightsidecontainer>
                <Box
                  style={{ display: " flex ", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Typography style={webStyle.trackname}>
                    <span style={webStyle.bluetext}>
                      Top {this.props.top40trackList.length < 40 ?this.props.top40trackList.length : 40}
                      </span> {index}
                  </Typography>
                  <Typography style={webStyle.splittext}>This week</Typography>
                </Box>
                <Typography
                  style={{
                    
                    marginTop:"10%",
                    paddingTop:"127px"
                  }}
                  className="no_data"
                >
                  Data Not Found.
                </Typography>
              </TracksboxRightsidecontainer>

            )}


          </>
        )}

      </>
    );
  }
}

const webStyle = {
  audioimagetext: {
    color: "#F0F0F5",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
    marginTop: "8px",
    maxWidth: "150px",
    textOverflow: "ellipsis",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap" as 'nowrap' 
  },

  audioartistext: {
    color: "#8f92a1",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Karla",
    lineHeight: "20px",
  },

  trackname: {
    fontSize: "22px",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    fontFamily: "Inter",
    whiteSpace: "nowrap" as "nowrap",
    color: "#ffffff",
  },
  statstab: {
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "1.36",
    fontFamily: "Inter",
    width: "225px",

    color: "#ffff",
    borderRadius: "35px",
    textTransform: "capitalize" as "capitalize",
  },

  bluetext: {
    fontSize: "22px",
    color: "#3959f9",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
  },

  splittext: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#8f92a1",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "normal",
    fontFamily: "Inter",
  },

  tracksimage: {
    height: "148px",
    width: "148px",
    // width: "148px",
    // height: "148px",
    // width: "148px",
    borderRadius: "12px",
  },
};
// Customizable Area End
