// Customizable Area Start
import React, { useContext, useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CaretRightOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import {
  defaultProfile,
  musicDollar,
  musicMore,
  musicNext,
  musicPlaylist,
  musicPrev,
} from "../../dashboard/src/assets";
import { pauseA, repeatA, volumeA, repeatBlue, addFriend } from "./assets";
import { useHistory } from "react-router-dom";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";
import axios from "axios";
import { openNotification } from "components/src/Notification.web";
const urlConfig = require("./../../../framework/src/config");
const baseUrl = urlConfig.baseURL;
import { getStorageData, setStorageData } from "./../../../framework/src/Utilities";

function CustomAudioPlayerCoverImage(props: any) {
  const {
    checkType,
    playlistData,
    attributes,
    artist_name,
    title,
    loopSys,
    AudioLoop,
    toPrevTrack,
    playList,
    loader,
    finishCheck,
    restart,
    playBt,
    pause,
    play,
    StartTimeCalc,
    nextOrder,
    seekBar,
    seekValue,
    endingTime,
    endTime,
    token,
    downloadType,
    sound,
    handleAddToPlaylist,
    soundOn,
    soundOff,
    volumeChange,
    nextTrack,
    currentindex,
    playListwholeList,
    trackTypeData,
    disableText,
    countUpdate,
    countData
  } = props;
  const history = useHistory();
  const { queueSongs } = useContext(AudioContextApi);

  const [isDisabled, setIsDisabled] = useState(true); // Initialize button as disabled
  const setIsFollowing = useState(false)[1];
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    if (nextTrack?.type === "list_ad_campaign") {
      setIsDisabled(true);
      timeoutId = setTimeout(() => {
      setIsDisabled(false);
      }, 15000);
    } else {
      setIsDisabled(false);
      updatePlayerCount()
   }
    return () => clearTimeout(timeoutId);

  }, [nextTrack]); 

  const updatePlayerCount=async()=>{
  const currentPlayerCount:any = await getStorageData("playerCount") || {};
  const trackType = nextTrack?.attributes.track_upload_type;

  const updatedPlayerCount = {
    ...currentPlayerCount, // Keep existing types
    [trackType]: (currentPlayerCount[trackType] || 0) + 1, // Increment count
  };

  setStorageData("playerCount", updatedPlayerCount);
  setStorageData("trackType", trackType);
  countUpdate(updatedPlayerCount)
  trackTypeData(trackType)

  if (currentPlayerCount[trackType] === 4) {
    processTrackQueue(trackType, updatedPlayerCount[trackType]);
  }

}

const processTrackQueue = async (trackType:string, nextTrackCount: number) => {
  const trackQueue:any = await getStorageData("trackQueue") || {};
  if (!trackQueue[trackType]) {
    trackQueue[trackType] = [];
  }
    try {
      const response = await axios.get(
        `${baseUrl}/bx_block_promotecontent/ad_campaigns/get_player_ads?upload_type=${trackType}`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            token: token,
          },
        }
      );
      const fetchedTracks = response.data || [];
      trackQueue[trackType] = fetchedTracks; // Update the queue with fetched tracks
      await setStorageData("trackQueue", trackQueue); // Save the updated queue
        disableText(fetchedTracks)

    } catch (error) {
      trackQueue[trackType] = [];
      await setStorageData("trackQueue", trackQueue); // Ensure empty array is stored
    }
  if (trackQueue[trackType].length > 0) {
    trackQueue[trackType].shift();
    setStorageData("trackQueue", trackQueue); // Save the updated queue
  }

    const updatedPlayerCount: any = await getStorageData("playerCount");
    updatedPlayerCount[trackType] = 0;
    setStorageData("playerCount", updatedPlayerCount);
};

  useEffect(() => {
    if (nextTrack?.type === "list_ad_campaign") {
      handleAdImpressionClick(playlistData.attributes.ad_campaign_id,"play")
    }
  },[])

  const playToolTip = () => {
    if (playBt) {
      return (
        <Tooltip placement="top" title="Pause">
          <img
            src={pauseA}
            id="music-icon"
            onClick={pause}
            className="music-play-icon"
            alt=""
          />
        </Tooltip>
      );
    } else {
      return (
        // play Buttion
        <Tooltip placement="top" title="Play">
          {
            <CaretRightOutlined
              id="coverImage_play_test_id"
              onClick={() => play(playlistData?.id)}
              style={{
                fontSize: "25px ",
                background: "#3959f9 ",
                borderRadius: "50%",
                padding: "7px 6px 7px 8px",
                color: "white",
              }}
            />
          }
        </Tooltip>
      );
    }
  };

   const handleAdImpressionClick = async (id:number,status:string) => {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
      token: token,
    };
  
    await axios
      .post(
        `${baseUrl}/bx_block_promotecontent/ad_campaigns/activity_in_ad_campaign`, 
        {id:id,status:status},{ headers }
      )
  };

  const handleFollowButtonAPICall = async (creativeId:any) => {
    const token = localStorage.getItem("token") || "";
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
  
    try {
      const response = await axios.get(
        `${baseUrl}/account_block/follow_user?user_id=${creativeId}`,
        {headers}
      );
      if (response.data.message === "User doesn't exist") {
        openNotification("User doesn't exist", "Error")
      } else {
        setIsFollowing(true); 
        openNotification("You successfully follow the artist", "Success") 
      }
    } catch {

    }
  };

  const finishCheckternary = () => {
    if (finishCheck()) {
      return (
        <CaretRightOutlined
          onClick={restart}
          style={{
            fontSize: "25px ",
            background: "#3959f9 ",
            borderRadius: "50%",
            padding: "7px 6px 7px 8px",
            color: "white",
          }}
        />
      );
    } else {
      return playToolTip();
    }
  };

  const returnArtistNamePlaylist=()=>{
    if(nextTrack?.type=="list_ad_campaign"){
      return(
           <span className="align-oneLine">
          <p className="ad-placement-text">Ad: </p><p className="song-title-custom">{artist_name || "Unknown"}</p>
          </span> 
      )
    }else{
      return(
      <p className="song-title-custom">{artist_name || "Unknown"}</p>
      )
    }
          
  }
  const audioRedirection = () =>{
    const userType = localStorage.getItem('user_type')
    if(userType == "label"){
      history.push('/deal')
      localStorage.setItem('splitType', "audio")
      localStorage.setItem('selectedAudio', playlistData?.id)
      localStorage.setItem('selectedAudioDealTrack', JSON.stringify(playlistData))
      localStorage.removeItem("selectedSingleVideo")
    }
  }

  const redirectExpandMore = () => {
    if (playlistData?.attributes?.track_id != ("" || null)) {
      
      if(window.location.href.includes('expand')){
         history.goBack()
      }else{
      history.push(
        `/home/expand/song/songId=${playlistData?.attributes?.track_id}`
      );
    }
    } else {
      if(window.location.href.includes('expand')){
        history.goBack()
     }else{
      history.push({
        pathname: `/home/expand/song/songId=${playlistData.id}`,
        // state: { data: playlistData },
      });
    }
    }
  };

  const returnPlaylistImage=()=>{
    if(checkType === "Playlists"){
              return playlistData.attributes.cover_image
    }else{
      return attributes?.artwork || attributes?.art_work ||attributes?.avatar ||attributes?.track_artwork ||defaultProfile
    } 
  }

  return (
    <div className="audio-player">
      <div className="song-details">
        <img
          src={
            returnPlaylistImage()
          }
          alt=""
        />
        <div className="label">
          <h3 className="song-title-custom">
            {checkType === "Playlists"
              ? playlistData?.attributes?.name
              : title ||
              attributes?.track_name ||
              attributes.track_title ||
              "Unknown"}
          </h3>
          {returnArtistNamePlaylist()}
        </div>
      </div>

      <div className="song-control">
        <div className="play-control">
          <Tooltip
            placement="top"
            title="Currently playing songs will be repeated"
          >
            {loopSys ? (
              <img
                id="music-icon"
                src={repeatBlue}
                onClick={() => AudioLoop(false)}
              />
            ) : (
              <img
                test-id="audio_loop_cover_image_test_id"
                src={repeatA}
                id="music-icon"
                onClick={() => AudioLoop(true)}
              />
            )}
          </Tooltip>
          <Tooltip placement="top" title="Previous">
            <input
              type="image"
              src={musicPrev}
              className="music-icon-size"
              onClick={toPrevTrack}
              style={{
                width: "38px",
                height: "37px",
                cursor: Array.isArray(queueSongs) && queueSongs.length > 0 ? "not-allowed" : "pointer",
              }}
              alt=""
              disabled={!Array.isArray(playList) || queueSongs.length > 0||isDisabled||currentindex === 0}
              test-id="music-next-click"
            />
          </Tooltip>
          {loader ? <CircularProgress /> : finishCheckternary()}
          <Tooltip placement="top" title="Next">
            <input
              type="image"
              src={musicNext}
              className="music-icon-size"
              onClick={nextOrder}
              alt=""
              disabled={!Array.isArray(playList)|| isDisabled||currentindex==playListwholeList.length-1}
              data-testId="next-click"
            />
          </Tooltip>

          <Tooltip placement="top" title="Redirect to Songs Details">
            <img
              test-id="redirectExpand_cover_image_test_id"
              src={musicMore}
              alt=""
              onClick={() => {
                nextTrack?.type === "list_ad_campaign" && handleAdImpressionClick(playlistData.attributes.ad_campaign_id,"click")
                redirectExpandMore()}
              }
              className="music-icon-size"
            />
          </Tooltip>
        </div>

        <div className="progress-bar">
          <p>
            {StartTimeCalc().minutes}:{StartTimeCalc().seconds}
          </p>
          <Slider
            className="slider-bar"
            onChange={seekBar}
            value={seekValue}
            trackStyle={{ backgroundColor: nextTrack?.type=="list_ad_campaign"?"yellow":"blue", height: "8px" }}
            tooltip={{
              open: false,
            }}
          />

          {endingTime ? (
            <p style={{ color: "#666669" }}>
              {endTime().minutes}:{endTime().seconds}
            </p>
          ) : (
            <p>0:0</p>
          )}
        </div>
      </div>
      <div
        className="song-volume"
        style={{
          gap: token ? "20px" : "45px",
        }}
      >
         <Tooltip placement="top" title="Download">
        <div onClick={() => 
                nextTrack?.type === "list_ad_campaign" && handleAdImpressionClick(playlistData.attributes.ad_campaign_id,"download") 
        }>
        {downloadType}
        </div>
        </Tooltip>
        
          <button
            onClick={()=>redirectExpandMore()}
            className="music-icon-size"
            style={{display:"none", margin:"0px", padding:"0px"}}
          />
           <Tooltip placement="top" title="Follow Artist">
         <div className="menuIcon cursor_pointer">
        
          <img
            src={addFriend}
            onClick={()=>handleFollowButtonAPICall(playlistData.attributes.ad_campaign_id)}
            className="music-icon-size"
            alt="Follow User"
            data-testId={"followBtnTestId"}
          />
         </div>
         </Tooltip>
         <Tooltip placement="top" title="Playlist">
        <div className="menuIcon cursor_pointer ">
          <img
            src={musicPlaylist}
            onClick={handleAddToPlaylist}
            className="music-icon-size"
            alt=""
          />
        </div>
        </Tooltip>
        <Tooltip placement="top" title="Deal">
        <div className="menuIcon cursor_pointer music-icon-size">
          <img test-id="audio_deal_cover_image_test_id" src={musicDollar} className={`music-icon-size ${localStorage.getItem('user_type') != 'label' && 'cursor-not-allowed'}`} alt="deal" 
          onClick={() => audioRedirection()} 
          />
        </div>
          </Tooltip>

        <div
          className="volune"
          style={
            {
                }
          }
        >
          {sound ? (
            <>
             <Tooltip placement="top" title="Sound">
              <VolumeOffIcon
                id="music-icon"
                onClick={soundOn}
                style={{ color: "white" }}
              />
              </Tooltip>
              <Slider
                className="seek-bar"
                trackStyle={{ backgroundColor: "red" }}
                onChange={volumeChange}
                min={0}
                max={1}
                step={0.01}
                value={0}
                tooltip={{
                  open: false,
                }}
              />
            </>
          ) : (
            <>
             <Tooltip placement="top" title="Sound">
              <img
                src={volumeA}
                id="music-icon-volumn"
                onClick={soundOff}
                alt=""
              />
              </Tooltip>
              <Slider
                className="seek-bar"
                trackStyle={{ backgroundColor: "red" }}
                onChange={volumeChange}
                min={0}
                max={1}
                step={0.01}
                defaultValue={1}
                tooltip={{
                  open: false,
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomAudioPlayerCoverImage;
// Customizable Area End
