// Customizable Area Start
import { Card } from "antd";
import React, { useRef, useEffect, useState } from "react";
import "../../../../packages/blocks/dashboard/assets/css/videoPlayer.css";
import {  loopIc, playNow,songPlay } from "../../dashboard/src/assets";
import { Typography, Grid } from "@material-ui/core";
import '../../user-profile-basic/src/Common/CardItem.web.css'
import axios from "axios";
import { openNotification } from "../../../../packages/components/src/Notification.web";
import { useHistory } from "react-router-dom";
import UnlockContentCommon from "../../../../packages/blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommon.web";
import { unlockRedirectionCheck } from "blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web";
//@ts-ignore
const urlConfig = require("../../../framework/src/config")
const baseUrl = urlConfig.baseURL;

const VideoCard = (props: any) => {
    const vedioPlayer: any = useRef();
    const [elapsedTime, setElapsedTime] = useState<number>(500)
    const [hide, setHide] = React.useState(true)
    const history = useHistory();

   
    const pause = () => {
        vedioPlayer?.current?.pause();
        props.setSelected(null)
        setHide(true)
    };
    const addPlayTrackCount = async (id: any) => {
        const token: any = localStorage.getItem("token")
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
        }
        await axios.get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, { headers })
            .then((response: any) => {

            }).catch((err: any) => {

                if (token) {
                    openNotification(err?.response?.data?.errors && err?.response?.data?.errors[0]?.token, "Error")
                }
            });


    }
    useEffect(() => {
      if (elapsedTime === 10000) {
        addPlayTrackCount(props.id);
      }
        if (props.handleAudioVideo !== 1) {
            pause()
            props.setHandleAudioVideo(props.handleAudioVideo)

        }
    }, [props.handleAudioVideo, elapsedTime])
   
    let vid = document.getElementById("myVideo");
    if (vid) {
        vid.onseeking = function (e) {
            e.stopPropagation()
        };
    }

    const handlerSingleVideos = async (id: any, trackFile: any) => {
        let outputData
        const token: any = localStorage.getItem("token")
        await axios.get(`${baseUrl}/bx_block_audiomusic/expan_track?id=${id}`, {
            headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
            }
        })
            .then((response: any) => {

                outputData = response.data.data

            }).catch((err: any) => {
                console.log(err);
            }).finally(() => {

            })




        history.push({
            pathname: "/home/Charts/videos",
            state: { video_list: trackFile, activeNo: "5", data: outputData, currentList: props.currentList ? props.currentList : [], trackAdsData: props.currentList[0] }
        })

    }
const {id,trackData, trackFile,selected,cardId,img}= props
    return (
        <div >
          <Grid container className="video-top-grid" >
            <Card className="video-img-slider" style={{ cursor: "pointer",border:"none",background:"transparent"  }} onClick={() => unlockRedirectionCheck(handlerSingleVideos,[id, trackFile],trackData)} >
              {/* <img className="video-card-slider" alt="example" src={image} /> */}
              {!hide && selected === cardId ?
                <video
                  ref={vedioPlayer}
                  id={cardId}
                  poster={img}
                  width="100%"
                  height="100%"
                  controls={true}
                  autoPlay={!hide}
                  style={{ objectFit: "cover", borderRadius: "0px" }}
                >
                  <source src={trackFile} type="video/mp4" />
                </video>
                :
                <>
                  <div className="pickgradient">
                    <img style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      display: "block",
                      borderRadius: "12px",
                      border:"1px solid black"
                    }}
     
     
                      src={img}
                    />
                  </div>
                  <img
                    id={cardId}
                    style={{
                      marginRight: "5px",
                      position: "absolute",
                      top: "40%",
                      left: "45%",
                      height: "50px",
                      cursor: "pointer",
                    }}
                    data-test-id={"handlerVideosTestId"}
                    onClick={(e) => {
                      unlockRedirectionCheck(handlerSingleVideos,[id, trackFile], trackData);
     
     
                    }}
                    src={songPlay}
                  />
                  <UnlockContentCommon trackData={trackData} width="91%"/>
                </>
              }
     
     
              <div
                className="songs-left"
              >
                {/* <div style={{display:"flex"}}> */}
              {trackData?.type == "list_ad_campaign" ?
                <span className="align-oneLines">
                  <p className="ad-placement-text-profile">Ad: </p>

                  <div className="originals-fonts-head">{props?.songName}</div>
                </span> 
                :
                <div className="originals-fonts-head">{props?.songName}</div>
              }
     
                {/* <div className="originals-fonts-head">{props?.songName}</div> */}

                {/* </div> */}
                <Grid
                container
                className="flex"
            >
                <Grid item xs={12} className="flex">
                    <Typography
                        className="mr_1 userName_width originals-subhead-font">
                        {props?.author}
                    </Typography>
                    <Typography className="flex" style={{ alignItems: "center" }}>
                        <img src={playNow} height="18px" width="25px" />
                        <span className="play-times_blue mr_05 text_blue videoList-color" style={{ marginLeft: "-3px" }}>
                            {props?.playCount}
                        </span>
                    </Typography>
                    <Typography className="flex" style={{ alignItems: "center" }}>
                        <img src={loopIc} height="14px" width="14px" />
                        <span className="play-times_blue text_blue videoList-color" style={{ marginLeft: "3px" }} >
                            {props?.repost_count}
                        </span>
                    </Typography>
                </Grid>
            </Grid>
              </div>
            </Card>
          </Grid>
        </div>
      );
};

export default VideoCard;
// Customizable Area End