// Customizable Area Start

import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { IBlock } from "../../../../../framework/src/IBlock";
import { HISTORY } from "components/src/common";
import { AudioContextApi } from "blocks/dashboard/src/AudioContext/AudioContext.web";
import { pauseA, playsong } from "blocks/dashboard/src/assets";
import { openNotification } from "components/src/Notification.web";
import { Message } from "../../../../../framework/src/Message";

export const configJSON = require("../../config");

export interface AudioData {
  data: {
    id: string;
    type: string;
    attributes: {
      title: string;
      level: string | null;
      unlocked_track_by_current_user: boolean;
      comments_count: number;
      repost_count: number;
      last_twenty_four_hours_percentage: number;
      last_seven_days_percentage: number;
      user_type: string;
      track_file: string;
      video_file: string | null;
      art_work: string;
      artist_name: string;
      artist_image: string | null;
      play_count: number;
      last_seven_days_graph: Record<string, number>;
      artist_id: number;
      user_follow: boolean;
    };
  }
}

export interface Props {
  webStyle: any;
  selectedTab: any;
  playlistDetailsData: any;
  selectedTrack: any;
  playlist_details_data: any;
  formatNumber: any;
  status: any;
  follow_playlist: any;
  followPlaylist: any;
  linkopenPopup: any;
  handleRepost: any;
  showSingleplaylistdeatils: any;
  selectedsong: any;
  showEditPlaylist: () => void;
  showDeletePlaylist: () => void;
  topPlayMusic: any;
  followUserStatus: any;
  followUser: boolean;
  is_invite: boolean;
  showDetailsPlaylistOpen:any

  accessList: any[];
  accessAvatarList: any[];
  accessListLoader: boolean;
  showAccessListView: boolean;
  setAccessListView:()=>void
  accessListPlayList:(trackId:number)=>void
}

interface S {
  localSeletedItem: any;
  openMessageSendPopupPlaylist: boolean;
  CopiedForLinkPlaylist: any;
  is_invite_user_playlist: boolean;
  openInvitationModalPlaylist: boolean;
  playeradsData: any;
  adsData: any;

}

interface SS {
  id: any;
}

interface SingleTrack{

    "id": string,
    "type": string,
    "attributes": {
      "title": string,
      "user_type": string,
      "track_file": string,
      "video_file": null,
      "art_work": string,
      "artist_name": string,
      "artist_image": string,
      "play_count": number,
      "comments_count": number,
      "repost_count": number,
      "last_twenty_four_hours_percentage": number,
      "last_seven_days_percentage": number,
      "last_seven_days_graph": {
        "Sunday": number,
        "Monday": number,
        "Tuesday": number,
        "Wednesday": number,
        "Thursday": number,
        "Friday": number,
        "Saturday": number
      },
      "artist_id": number,
      "user_follow": boolean
  }
}

export default class SinglePlaylistController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiInviteUser: any;
  apiAccessListPlaylist:string="";
  apigetDisplayAds: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ];

    this.state = {
      localSeletedItem: false,
      openInvitationModalPlaylist: false,
      openMessageSendPopupPlaylist: false,
      CopiedForLinkPlaylist: "",
      is_invite_user_playlist: false,
      playeradsData: [],
      adsData: []
      // accessList: [],
      // accessAvatarList: [],
      // accessListLoader: false,
      // showAccessListView: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   handleRemoveAudioPlayer=async()=>{
   await this.context.removeAudioPlayer()
  }
  async componentDidMount() {
    super.componentDidMount();
    this.setState({playeradsData: this.props.playlistDetailsData.attributes?.playlist_items})
    this.getDisplayAds();
    await this.handleRemoveAudioPlayer()
    if(this.props.selectedTab==="audio"){
    this.props.topPlayMusic(
      this.props.playlist_details_data,
      2,
      this.props.playlistDetailsData.attributes?.playlist_items?.data,
      "track"
    );
    this.props.selectedTrack(this.props.playlist_details_data)
    }else{
      this.props.selectedTrack(this.props.playlist_details_data)
    }
      this.props.accessListPlayList(this.props.playlist_details_data.id)

  }
 

  handleShowPlaylist = (data: any) => {
    alert(data)
  };

  openInbox = () => {
    let loggedInUser = localStorage.getItem("loggedIn_userId");
    if (
      this.props.playlist_details_data.attributes.artist_id == loggedInUser
    ) {
      openNotification("You cannot message yourself", "Error");
    } else {
      localStorage.setItem(
        "trackUserListDataDM",
        JSON.stringify(this.props.playlist_details_data)
      );
      HISTORY.push({
        pathname: "/comments",
      });
    }
  };
  openSinglePlaylistData = async(item: SingleTrack) => {
    this.props.selectedTrack(item);
    this.setState({ localSeletedItem: true })
      this.props.topPlayMusic(
        item,
        12121,
        this.props.playlistDetailsData.attributes?.playlist_items?.data,
        "track"
      );
  };
  backtoPlaylist =()=>{
    this.props.showDetailsPlaylistOpen("playist")
  }

  showPlayPause = (item: any) => {
    let { handleAudioVideo, playlistData } = this.context;
    if (playlistData?.id == item.id && handleAudioVideo === 2) {
      return pauseA;
    } else {
      return playsong;
    }
  };
  redirectVideo = (item: any) => {
    HISTORY.push({
      pathname: "/home/Charts/videos",
      state: {
        video_list: item.attributes.video_file,
        activeNo: "5",
        data: item,
        currentList: this.props.playlistDetailsData
          ? this.props.playlistDetailsData.attributes?.playlist_items?.data
          : [],
      },
    });
  };
  handleOpenInvitation = () => {
    this.setState({ openInvitationModalPlaylist: true });
  };

  handleCloseInvitation = () => {
    this.setState({ openInvitationModalPlaylist: false });
  };

  linkopenPopup = () => {
    this.setState({
      CopiedForLinkPlaylist: window.location.origin.concat(
        `/invitation/playlistId=${this.props.playlistDetailsData.id}`
      ),
    });
    this.setState({ openMessageSendPopupPlaylist: true });
    // this.onInviteUser()
  };
  handleOpenMessageSendPopup = () => {
    this.setState({ openMessageSendPopupPlaylist: false });
  };

  getDisplayAds = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetDisplayAds = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl + `${configJSON.displayadsEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  onInviteUser = (selectedUser: any) => {
    debugger
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiInviteUser = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseUrl +
        `/account_block/invite_users/send_invite?account_id=${selectedUser?.id}&track_id=${this.props.playlist_details_data.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleInviteUserPlaylist = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.errors) {
      openNotification(responseJson.message, "Success");
    } else {
      openNotification(responseJson.errors, "Error");
    }
  };
 
  handleDisplaySuccess = (response: any) => {
    if (response !== undefined && !response.error && !response.message) {

      const transformData = response?.data?.map((item: any) => ({
        data: item
      }));

      this.setState({ adsData: transformData }, () => {
        this.handleShortData()
      })
    }
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId == this.apiInviteUser) {
      this.handleInviteUserPlaylist(responseJson);
    } else if (apiRequestCallId == this.apigetDisplayAds){
      this.handleDisplaySuccess(responseJson)
    }
    

    // Customizable Area End
  }
  handleShortData = () => {
    this.setState((prevState) => {
      const combinedAndSortedData = [
        ...prevState.adsData,
        ...prevState.playeradsData
      ].sort((a, b) => a.data.attributes.play_count - b.data.attributes.play_count);

      return {
        playeradsData: combinedAndSortedData,
      }
    },() => {console.log(this.state.playeradsData,"### MMMMDVWFV")})
  };
}
SinglePlaylistController.contextType = AudioContextApi;
// Customizable Area End
