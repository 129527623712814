// Customizable Area Start
import React, { useContext, useEffect, useRef, useState } from "react";
import "./customAudioPlayer.css";
import {
  album1,
  album2,
  album3,
  musicDownload,
} from "../../dashboard/src/assets";
import {
  Col,
  Form,
  Modal,
  Row,
  Select,
  Slider,
  Spin,
  Space,
  Input,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import LoginAlert from 'components/src/CustomChart/PopUps/LoginAlert.web'
import { openNotification } from "../../../components/src/Notification.web";
import { useHistory } from "react-router-dom";
import { Grid, Hidden, Button, ButtonGroup } from "@material-ui/core";
import CustomAudioPlayerCoverImage from "./CustomAudioPlayerCoverImage.web";
import CustomAudioPlayerCenterIcons from "./CustomAudioPlayerCenterIcons.web";
import CustomAudioPlayerRightIcons from "./CustomAudioPlayerRightIcons.web";
import CustomAudioCloseLogin from "./CustomAudioCloseLogin.web";
import { AudioContextApi } from "../../dashboard/src/AudioContext/AudioContext.web";
import { unlockRedirectionCheck } from "blocks/DetailedDownloadHistory/src/StreamUnlock/Common/UnlockContentCommonController.web";
import { getStorageData } from "./../../../framework/src/Utilities";
const urlConfig = require("./../../../framework/src/config");
const baseUrl = urlConfig.baseURL;


const IconColor: any = {
  fontSize: "25px ",
  background: "blue ",
  borderRadius: "50%",
  padding: "7px",
};


export const handleAddToPlaylist = (history: any,playlistData: any, playList: any,checkType: any,playlistQueue: any) => {
  if(window.location.href.includes('playlist-queue')){
   return history.goBack()
  }
  if (playlistData.type == "playlist") {
    history.push({
      pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
      state: { data: playlistData, playlist: playlistData },
    });
  } else if (playList.type == "playlist" || playList.type == "Playlist") {
    history.push({
      pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
      state: { data: playlistData, playlist: playList },
    });
  } else {
    if (checkType.includes("Playlist") || checkType.includes("playlist")) {
      history.push({
        pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
        state: { data: playlistData, playlist: playlistQueue },
      });
    } else {
      if (playlistData?.attributes?.track_id != ("" || null)) {
        history.push(
          `/home/playlist-queue/audio/id=${playlistData?.attributes?.track_id}`
        );
      } else {
        history.push({
          pathname: `/home/playlist-queue/audio/id=${playlistData.id}`,
          state: { data: playlistData },
        });
      }
    }
  }
};

export const toPrevTrackIsShuffleIfCondition = (wholeList: any, setNextTrack: any, play: any,cryptoRandomNumber: any) =>{
  let item = wholeList[Math.floor(cryptoRandomNumber())];
  unlockRedirectionCheck(setNextTrack,[item],item);
  unlockRedirectionCheck(play,[item?.id],item);
}

export const toPrevTrackIsShuffleElseCondition = (setNextTrack: any,wholeList: any, index: any, play: any,setPlaylistData: any,setElapsedTime: any ) =>{
  unlockRedirectionCheck(setNextTrack,[wholeList[index - 1]],wholeList[index - 1]);
  setPlaylistData(wholeList[index - 1]);
  unlockRedirectionCheck(play,[wholeList[index - 1]?.id],wholeList[index - 1]);
  setElapsedTime(0)
}

export const normalListPlaySongsIfCondition = (setNextTrack: any,nextPlaylistTrack: any, setPlaylistData: any,play: any) =>{
  unlockRedirectionCheck(setNextTrack,[nextPlaylistTrack],nextPlaylistTrack);
  setPlaylistData(nextPlaylistTrack);
  unlockRedirectionCheck(play,[nextPlaylistTrack?.id],nextPlaylistTrack);
}

export const normalListPlaySongsElseCondition = async (setNextTrack: any, wholeList: any, index: any, setPlaylistData: any, play: any, attr: any,setNextPlaylistTrack: any ) =>{

  unlockRedirectionCheck(setNextTrack,[wholeList[index + 1]],wholeList[index + 1]);
  setPlaylistData(wholeList[index + 1]);
  unlockRedirectionCheck(play,[wholeList[index + 1]?.id],wholeList[index + 1]);
  if (attr.isplaylist) {
    setNextPlaylistTrack(wholeList[index + 2]);
  }
}

export const nextOrderIsShuffleIfCondition = async(setNextTrack: any, item: any, play: any,setElapsedTime: any) =>{
  unlockRedirectionCheck(setNextTrack,[item],item);
  unlockRedirectionCheck(play,[item?.id], item);
  setElapsedTime(0)
}

export const queueSongsIfCondition = (setNextTrack: any,contextQueueSongs: any,play: any, setPlaylistData: any) =>{
  unlockRedirectionCheck(setNextTrack,[contextQueueSongs[0]],contextQueueSongs[0]);
  setPlaylistData(contextQueueSongs[0]);
  unlockRedirectionCheck(play,[contextQueueSongs[0]?.id],contextQueueSongs[0]);
  contextQueueSongs.splice(0, 1);
  localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
}

export const queueSongsElseIfStorageCondition = (setNextTrack: any,contextQueueSongs: any,play: any, setPlaylistData: any, nextPlaylistTrack: any) =>{
  contextQueueSongs.splice(0, 1);
  localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
  unlockRedirectionCheck(setNextTrack,[nextPlaylistTrack],nextPlaylistTrack);
  setPlaylistData(nextPlaylistTrack);
  unlockRedirectionCheck(play,[nextPlaylistTrack?.id],nextPlaylistTrack);
}

export const queueSongsIsPlaylistIfCondition = (setNextTrack: any,contextQueueSongs: any,play: any, setPlaylistData: any, wholeList: any) =>{
  unlockRedirectionCheck(setNextTrack,[wholeList[0]],wholeList[0]);
  setPlaylistData(wholeList[0]);
  unlockRedirectionCheck(play,[wholeList[0]?.id],wholeList[0]);
  contextQueueSongs.splice(0, 1);
  localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
}

export const queueSongsIsPlaylistElseCondition = (setNextTrack: any,play: any, setPlaylistData: any, wholeList: any, index: any,setNextPlaylistTrack: any) =>{
  unlockRedirectionCheck(setNextTrack,[wholeList[index + 1]],wholeList[index + 1]);
  setPlaylistData(wholeList[index + 1]);
  unlockRedirectionCheck(play,[wholeList[index + 1]?.id],wholeList[index + 1]);
  setNextPlaylistTrack(wholeList[index + 2]);
}

export const nextElseQueueSongs = (index: any, contextQueueSongs: any,setPlaylistData:any, setNextTrack: any, play: any) => {
  if (contextQueueSongs.length > 0) {
    unlockRedirectionCheck(setNextTrack,[contextQueueSongs[index + 1]],contextQueueSongs[index + 1]);
    setPlaylistData(contextQueueSongs[index + 1]);
    unlockRedirectionCheck(play,[contextQueueSongs[index + 1]?.id],contextQueueSongs[index + 1]);
  }
};

const AudioPlayerFun = (props: any) => {
  const { track_file, songSrc, audioPlayer, onPlaying } = props;
  return (
    <audio
      autoPlay={true}
      src={track_file || songSrc}
      ref={audioPlayer}
      onTimeUpdate={onPlaying}
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
};

export const SmMobileView = (props: any) => {
  const { title, artist_name } = props;
  return (
    <div className="mobile-view">
      <div className="label">
        <h3 className="mobile-view-audio-title">{title || "Unknown"}</h3>
        <p>{artist_name || "Unknown"}</p>
      </div>
    </div>
  );
};

export const UploadCoverArt = (props: any) => {
  const { imageUrl } = props;
  return (
    <div style={{ display: "flex" }}>
      <img
        style={{
          height: "48px",
          width: "48px",
          borderRadius: "50%",
          background: "#f5f5f5",
        }}
        src={
          imageUrl
            ? imageUrl
            : "https://images.squarespace-cdn.com/content/v1/56e19ec5e3214084d69d4b7d/1473524254173-BGV5W2Z5FM46F67SYZHJ/PlusIcon_Small_Gray.png"
        }
        alt=""
      />
      &nbsp;&nbsp;
      <p
        aria-label="upload picture"
        style={{ color: "#8f92a1", marginTop: "11px" }}
      >
        Upload cover art
      </p>
    </div>
  );
};

export const UserPlaylistChild = (userplaylist: any, Option: any) =>{
  return(
    <>
     {userplaylist &&
        userplaylist.map((ind: any) => (
          <Option value={ind?.id} key={ind?.id}>
            {ind?.attributes?.name}
          </Option>
        ))}
    </>
  )
}





const CustomAudioPlayer = ({
  setEndingTimeApp,
  setCurrentTimeApp,
  setPlaylistData,
  handleAudioVideo,
  setHandleAudioVideo,
  playlistData,
  playList,
  checkType,
  removeAudioPlayer,
  playlistQueue,
  contextQueueSongs,
  setNextPlaylistTrackFunction,
  playlistQueueSongs,
  setAudioLoader,
  audioLoader,
  removeNowPlayingSong,
  countData
}: any) => {
  const [playBt, setPlayBt] = useState<any>(true);
  const audioPlayer: any = useRef();
  const [currentTime, setCurrentTime] = useState<any>(0);
  const [seekValue, setSeekValue] = useState(0);
  const [endingTime, setEndingTime] = useState<any>(0);
  const [sound, setSound] = useState<any>(false);
  const [loopSys, setLoopSys] = useState<any>(false);
  const [nextTrack, setNextTrack] = useState<any>(playlistData);
  const [trackIndex, setTrackIndex] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [dialogText, setDialogText] = useState<any>("");
  const [openAlertLogin, setopenAlertLogin] = useState(false);
  const [dialogTextAlertLogin, setDialogTextAlertLogin] = useState<any>(false);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [musicButton, setMusicButton] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [songId, setSongId] = useState<number>();
  const [closeAudio, setCloseAudio] = useState<any>(false);
  const [isplaylist, setIsPlaylist] = useState<any>(false);
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);
  const [updatedCount, setUpdatedCount] = useState<any>();
  const [updateTrack, setUpdatedTack] = useState<string>();
  const [updateDisbale, setUpdateDisabe] = useState<boolean>();
  const token: any = localStorage.getItem("token");
  const user_id: any = localStorage.getItem("user_id");
  const userType: any = localStorage.getItem("user_type");
  const history = useHistory();
  const downloadRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (typeof playlistData === "string") { 
      setElapsedTime(0)
      unlockRedirectionCheck(setNextTrack,[JSON.parse(playlistData)],JSON.parse(playlistData));
    }
  }, [playlistData]);

  let wholeList = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");

  if (isplaylist) {
    let queueList = JSON.parse(
      localStorage.getItem("queuePlaylistSongs") || "[]"
    );
    if (queueList.length > 0) {
      wholeList = queueList;
    }
  }
  const [nextPlaylistTrack, setNextPlaylistTrack] = useState<any>(
    wholeList.length > 1 ? wholeList[1] : playlistData
  );

  const attributes  = nextTrack?.attributes;
  const  artist_name  = attributes?.artist_name || {};
  const   title  = attributes?.title || {};
  const   track_file  = attributes?.track_file || {};

  const { setNextSongForPlay } = useContext(AudioContextApi);

  useEffect(() => {
    const removeId = removeNowPlayingSong.id;
    if (removeId) {
      if (removeId == nextTrack?.id) {
        nextOrder();
        setNextSongForPlay(nextTrack);
      }
    }
  }, [removeNowPlayingSong]);
  useEffect(() => {
    let nextUpSongs = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");
    if (nextUpSongs.length > 0) {
      const index = nextUpSongs.findIndex(
        (item: any) => item.id === nextPlaylistTrack?.id
      );
      if (index == -1 && nextUpSongs.length > 0) {
        setNextPlaylistTrack(wholeList[0]);
      }
      if (nextUpSongs.length > 0) {
        wholeList = JSON.parse(localStorage.getItem("nextUpSongs") || "[]");
      } else {
        wholeList =
          typeof playList === "string" ? JSON.parse(playList) : playList;
      }
    }
  }, [localStorage.getItem("nextUpSongs")]);

  useEffect(() => {
    const index = contextQueueSongs.findIndex(
      (item: any) => item.id === nextTrack?.id
    );
    if (index == 1) {
      contextQueueSongs.splice(0, 1);
      localStorage.setItem("queueSongs", JSON.stringify(contextQueueSongs));
    }
  }, [nextTrack]);

  useEffect(() => {
    setNextPlaylistTrackFunction(nextPlaylistTrack);
  }, [nextPlaylistTrack]);

  useEffect(() => {
    if (localStorage.getItem("queuePlaylistSongs")) {
      setIsPlaylist(true);
    } else {
      setIsPlaylist(false);
    }
  }, [localStorage.getItem("queuePlaylistSongs"), playlistQueueSongs]);

  const queueSongsNextOrder = () => {
    const index = contextQueueSongs.findIndex(
      (item: any) => item.id === nextTrack?.id
    );
  
    if (index === -1) {
      queueSongsIfCondition(setNextTrack,contextQueueSongs,play, setPlaylistData)
    } else if (
      index == 0 &&
      nextTrack?.id == JSON.parse(localStorage.getItem("selectedSong") || "")
    ) {
      queueSongsElseIfStorageCondition(setNextTrack,contextQueueSongs,play, setPlaylistData, nextPlaylistTrack)
    } else if (index === contextQueueSongs.length - 1) {
      if (isplaylist) {
        const index = wholeList.findIndex(
          (item: any) => item.id === nextTrack?.id
        );
        if (index === -1) {
          queueSongsIsPlaylistIfCondition(setNextTrack,contextQueueSongs,play, setPlaylistData, wholeList)
        } else if (index === wholeList.length - 1) {
          return null;
        } else {
          queueSongsIsPlaylistElseCondition(setNextTrack,play, setPlaylistData, wholeList, index,setNextPlaylistTrack)
        }
      } else {
        return null;
      }
    } else {
      nextElseQueueSongs(index,contextQueueSongs,setPlaylistData, setNextTrack,play);
    }
  };

  const normalListPlaySongs = () => {
    const index = wholeList.findIndex((item: any) => item.id === nextTrack?.id);
    if (index === -1) {
      if (nextPlaylistTrack) {
        if (
          nextPlaylistTrack?.id !=
          JSON.parse(localStorage.getItem("selectedSong") || "")
        ) {
          normalListPlaySongsIfCondition(setNextTrack,nextPlaylistTrack, setPlaylistData,play)
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else if (index === wholeList.length - 1) {
      return null;
    } else {
      const attr = {isplaylist, updatedCount, updateTrack, updateDisbale}
      normalListPlaySongsElseCondition(setNextTrack, wholeList, index, setPlaylistData, play, attr,setNextPlaylistTrack)
    }
  };

  const cryptoRandomNumber = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] / 0xFFFFFFFF;
  };
  

  const nextOrder = async () => {
    setDownloadUrl(undefined)
    setAudioLoader(true);
    pause();
    setIsActive(false);
    if (loopSys) {
      await restart();
      setPlayBt(true);
    } else {
      if (isShuffle) {
        let item = wholeList[Math.floor(cryptoRandomNumber() * wholeList.length)];
        nextOrderIsShuffleIfCondition(setNextTrack, item, play,setElapsedTime)
      } else {
        if (contextQueueSongs.length > 0) {
          queueSongsNextOrder();
          setElapsedTime(0)
        } else {
          normalListPlaySongs();
        setElapsedTime(0)
        }
      }
    }
    await setAudioLoader(false);
  };


  useEffect(() => {
    unlockRedirectionCheck(setNextTrack,[playlistData],playlistData);
    unlockRedirectionCheck(play,[playlistData?.id],playlistData);
    setElapsedTime(0)
    setIsActive(true)
      setIsPaused(false)
  }, [playlistData]);
  const tracks: any = [
    {
      id: 1,
      songSrc:
        "http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/theme_01.mp3",
      songImg: `${album1}`,
      songName: "abcc",
      songArtist: "rock",
    },
    {
      id: 2,
      songSrc:
        "http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/lose.ogg",
      songImg: `${album2}`,
      songName: "abcc",
      songArtist: "rock",
    },
    {
      id: 3,
      songSrc:
        "http://commondatastorage.googleapis.com/codeskulptor-demos/riceracer_assets/music/menu.ogg",
      songImg: `${album3}`,
      songName: "abcc",
      songArtist: "rock",
    },
  ];

  useEffect(() => {
    if (currentTime == 0) {
      stop();
    } else {
      if (playBt) {
        audioPlayer.current.play();
      } else {
      }
    }
  }, [trackIndex, playBt]);
  useEffect(() => {
    if (currentTime !== 0 && endingTime !== 0) {
      if (currentTime === endingTime) {
        nextOrder();
      }
    }
  }, [currentTime, endingTime]);


  useEffect(() => {
    let interval: any = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setElapsedTime((elapsedTime) => elapsedTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    elapsedTime == 30000 && addPlayTrackCount(songId);
  }, [songId, elapsedTime]);

  const addPlayTrackCount = async (id: any) => {
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",

      token: token,
    };
    await axios
      .get(`${baseUrl}/bx_block_audiomusic/play_track?id=${id}`, { headers })
      .then((response: any) => {})
      .catch((err: any) => {
        if (token) {
          openNotification(
            err?.response?.data?.errors &&
              err?.response?.data?.errors[0]?.token,
            "Error"
          );
        } else {
        }
      });
  };


    let { songSrc } = trackIndex !== undefined &&  tracks[trackIndex];
  
  const play = async (id?: any) => {
    // setStorageData("playerCount",{nextOrder.id})
    onPlaying()
    localStorage.setItem("selectedSong", JSON.stringify(id));
    localStorage.setItem("selectedAudio",JSON.stringify(id))
    setPlayBt(true);
    setAudioLoader(true);
    const timer = setTimeout(async () => {
      await audioPlayer.current.play();
      setHandleAudioVideo(2);
      await setAudioLoader(false);
    }, 500);
    if (typeof id !== "object" && id) {
      setSongId(id);
    } else if (typeof id === "object" && id) {
      setSongId(nextTrack?.id);
    }

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    if (handleAudioVideo === 1) {
      audioPlayer.current.pause();
      setPlayBt(false);
      setIsActive(false)
      setIsPaused(true)
    } else {
      setIsActive(true)
      setIsPaused(false)
      setPlayBt(true);
    }
  }, [handleAudioVideo]);
  const pause = async () => {
    await setAudioLoader(true);
    await audioPlayer.current.pause();
    setPlayBt(false);
    setHandleAudioVideo(1);
    setAudioLoader(false);
  };
  const stop = async () => {
    setAudioLoader(true);
    setIsActive(false);
    await audioPlayer.current.pause();
    audioPlayer.current.currentTime = 0;
    setAudioLoader(false);
  };
  const restart = async () => {
    setAudioLoader(true);
    audioPlayer.current.currentTime = 0;
    await audioPlayer.current.play();
    setHandleAudioVideo(2);
    setAudioLoader(false);

    // addPlayTrackCount(nextTrack?.id)
  };
  const setSpeed = (speed: any) => {
    audioPlayer.current.playbackRate = speed;
  };

  // todo...........
  // issue is : when ending Time not geting ui is stcukkkk.....

  const seekBar = (value: any) => {
    // setSeekValue((value / endingTime) * 100);
    const seek = (endingTime * value) / 100;
    audioPlayer.current.currentTime = seek;
  };

  const onPlaying = () => {
    //  setIsActive(true);
    //  setIsPaused(false);
    setCurrentTimeApp(audioPlayer.current.currentTime);
    setCurrentTime(audioPlayer.current.currentTime);
    setSeekValue(
      (audioPlayer.current.currentTime / audioPlayer.current.duration) * 100
    );
    setEndingTimeApp(audioPlayer.current.duration);
    setEndingTime(audioPlayer.current.duration);
  };

  const StartTimeCalc = () => {
    const minute = Math.floor(currentTime / 60);
    const minutes: any = minute <= 9 ? "0" + Number(minute) : minute;
    const second = parseInt(currentTime) - minutes * 60;
    const seconds: any = second <= 9 ? "0" + Number(second) : second;
    return {
      minutes,
      seconds,
    };
  };
  const endTime = () => {
    const minute = Math.floor(endingTime / 60);
    const minutes: any = minute <= 9 ? "0" + Number(minute) : minute;
    const second = parseInt(endingTime) - minutes * 60;
    const seconds: any = second <= 9 ? "0" + Number(second) : second;
    return {
      minutes,
      seconds,
    };
  };

  const volumeChange: any = (value: any) => {
    audioPlayer.current.volume = value;
    setSound(false);
    soundOn();
  };

  const soundOff: any = () => {
    audioPlayer.current.muted = true;
    setSound(true);
  };
  const soundOn: any = () => {
    audioPlayer.current.muted = false;
    setSound(false);
  };

  const AudioLoop: any = (bool: any) => {
    audioPlayer.current.loop = bool;
    setLoopSys(bool);
  };

  const updatedCountData = (item: any) => {
    setUpdatedCount(item)
  }; 

  const updatedTrack = (item: string) => {
    setUpdatedTack(item)
  }; 

  const updateDisable = (item: any) => {
    unlockRedirectionCheck(setNextTrack,[item.data[0]],item.data[0]);
    unlockRedirectionCheck(play,[item.data[0].id],item.data[0]);
    setElapsedTime(0)
    setIsActive(true)
    setIsPaused(false)

  }; 
  const toPrevTrack = async() => {
    setAudioLoader(true);
    setDownloadUrl(undefined)
    await pause();
    if (isShuffle) {
      toPrevTrackIsShuffleIfCondition(wholeList, setNextTrack, play,cryptoRandomNumber)
    } else {
      const index = wholeList.findIndex(
        (item: any) => item.id === nextTrack?.id
      );
      if (index === 0 || index === -1 ) {
        // No such order or no next order
        return null;
      } else {
        toPrevTrackIsShuffleElseCondition(setNextTrack,wholeList, index, play,setPlaylistData,setElapsedTime)

      }
    }
    await setAudioLoader(false);
  };

  const toNextTrack = () => {
    if (trackIndex < tracks.length - 1) {
      setTrackIndex(trackIndex + 1);
    } else {
      setTrackIndex(0);
    }
  };
  const finishCheck = () => {
    if (currentTime === endingTime) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setDialogText(token ? "1" : "2");
  };

  const handleClose = async () => {
    await setAudioLoader(true);
    setOpen(false);
    await setAudioLoader(false);
  };

  const [openPlaylistModal, setOpenPlaylistModal] = useState<any>(false);
  const [userplaylist, setUserplaylist] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [emptyPlaylist, setEmptyPlaylist] = useState(false);
  const [newPlaylistId, setNewPlaylistId] = useState<any>("");
  const [isShuffle, setIsShuffle] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const { Option } = Select;

  const handleCloseAlertLogin = () => {
    setopenAlertLogin(false);
  };
  const getUserPlaylist = async () => {
    setLoading(true);
    await axios
      .get(`${baseUrl}/bx_block_playlist/play_lists`, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((response: any) => {
        setLoading(false);
        setUserplaylist(response.data.data);
      })
      .catch((err: any) => {
        setLoading(false);
        err?.response.status === 422 && setEmptyPlaylist(true);
      });
  };

  const handleAddTrackToPlaylist = async (values: any) => {
    setLoading(true);
    const data = {
      add_track_id: playlistData.id,
      play_list_id: playlistId,
    };
    await axios
      .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        openNotification(
          "This song is successfully added in playlist",
          "Success"
        );
        setOpenPlaylistModal(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl("");
      })
      .catch((err: any) => {
        if (err?.response?.status === 409) {
          openNotification(err.response.data.data[0].message, "Error");
        } else if (err?.response?.status === 400) {
          openNotification(err?.response?.data?.errors[0]?.token, "Error");
        } else {
        }
      })
      .finally(() => {
        setOpenPlaylistModal(false);
        setLoading(false);
        form.resetFields();
        form1.resetFields();
        setimageUrl("");
      });
    setimageUrl("");
  };

  const [playlistId, setPlaylistId] = useState<any>("");
  const [addTrackId, setAddTrackId] = useState<any>(playlistData.id);
  function onChange(value: any) {
    setPlaylistId(value);
  }

  function onSearch(val: any) {
  }
  const [imageUrl, setimageUrl] = useState<any>("");
  const [fileName, setfileName] = useState<any>("");
  const [playlistName, setplaylistName] = useState<any>("");
  const fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    let files = target.files as any;

    if (files.length !== 0) {
      let url = URL.createObjectURL(files[0]);
      setimageUrl(url);
      for (const file of files) {
        setfileName(files);
      }
    }
  };

  const UploadPlayLists = async (fileName: any) => {
    setLoading(true);
    const token: any = localStorage.getItem("token");
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",

      token: token,
    };
    if (fileName && playlistName) {
      let formData = new FormData();
      for (const key of fileName) {
        //formdata object
        formData.append("name", playlistName);
        formData.append("cover_image", key);
        // checking typingg.....
        formData.append("play_list_type", musicButton ? "Video" : "Audio");
      }
      document
        .getElementById("createPlayListBtn")
        ?.setAttribute("disabled", "true");  //changes done here true replaced with "true"
      document
        .getElementById("createPlayListBtn")
        ?.setAttribute("style", "background :#1890ff; color:#fff; width:100%;");
      await axios
        .post(`${baseUrl}/bx_block_playlist/play_lists`, formData, { headers })
        .then(async (response: any) => {
          openNotification("New PlayList Added Successfully", "Success");
          const data = {
            add_track_id: playlistData.id,
            play_list_id: response.data.data.id,
          };

          await axios
            .post(`${baseUrl}/bx_block_playlist/add_track_to_playlist?`, data, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",

                token: token,
              },
            })
            .then((res) => {
              // openNotification("This song is successfully added in playlist", "Success")
              setOpenPlaylistModal(false);
              form1.resetFields();
            })
            .catch((err) => {
              openNotification(
                err?.response?.data?.errors[0]?.account,
                "Error"
              );
              openNotification(err.response.data[0].message, "Error");
            })
            .finally(() => {
              setOpenPlaylistModal(false);
              form.resetFields();
              form1.resetFields();
              setimageUrl("");
            });
        })
        .catch((err: any) => {
          openNotification("Playlist of this name already exist.", "Error");
          err?.response?.status === 422 &&
            openNotification(err?.response?.data?.errors[0]?.name, "Error");
          err?.response?.status === 400 &&
            openNotification(err?.response?.data?.errors[0]?.token, "Error");
        })
        .finally(() => {
          setLoading(false);
          form.resetFields();
          form1.resetFields();
          setimageUrl("");
        });
    }
  };

  const redirectToDownloads = () => {
    if (!token) {
      setopenAlertLogin(true);
      setDialogTextAlertLogin(token ? "1" : "2");
    } else {
      history.push("/home/downloadPage");
    }
  };
  const handleAddTrackToCreatePlaylist = async (values: any) => {
    const data = {
      add_track_id: playlistData.id,
      play_list_id: newPlaylistId,
    };
    await axios
      .post(`${baseUrl}/bx_block_playlist/add_track_to_playlis?`, data, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",

          token: token,
        },
      })
      .then((res) => {
        openNotification(
          "This song is successfully added in playlist",
          "Success"
        );
        setOpenPlaylistModal(false);
      })
      .catch((err) => {
        openNotification(err?.response?.data?.errors[0]?.account, "Error");
      })
      .finally(() => {
        setOpenPlaylistModal(false);
      });
  };
  const handleClosePlaylistModal = () => {
    form.resetFields();
    form1.resetFields();
    setimageUrl("");
    setOpenPlaylistModal(false);
  };

  const saveFile = (url: any, name: any) => {
    const url1: any = window.URL.createObjectURL(new Blob([url]));
    var anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  useEffect(() => {
    if (downloadUrl && downloadRef.current) {
      downloadRef.current.click();
      setDownloadUrl(undefined); // Reset URL after triggering download to avoid repeated downloads
    }
  }, [downloadUrl]);
  
  const saveDownloadHistory = async () => {
    const lowerCaseCheckType = checkType.toLowerCase();
    if ((lowerCaseCheckType !== "songs") && (userType.includes("listener") || userType.includes("listener_plus"))) {
      if (lowerCaseCheckType !== "songs" && lowerCaseCheckType !== "song") {
        setOpen(true);
        setDialogText(userType.includes("listener") || userType.includes("listener_plus") ? "3" : "2");
    
    }
    } else {
      const data = {
        account_id: user_id,
        add_track_id: playlistData.id,
      };
      await axios
        .post(`${baseUrl}/download_histories`, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",

            token: token,
          },
        })
        .then((res) => {
        if(res.data.message=="download success" ){

          setDownloadUrl(`${track_file}?response-content-disposition=attachment`);
        }else{
          openNotification(res.data.message, "Error")
        }
        })
        .catch((err: any) => {
          
          openNotification("Download history saving failed", "Error")
        });
    }
  };

  let downloadType;

  const isDownload = () => {
    if (attributes?.is_download) {
      downloadType = (
        <img
          src={musicDownload}
          className="music-icon-size"
          onClick={redirectToDownloads}
        />
      );
    } else {
      downloadType = (
        <a
          href={!(userType?.includes("listener")|| userType?.includes("listener_plus")) || 
          ((userType?.includes("listener")||userType?.includes("listener_plus")) && (checkType.toLowerCase() == "songs"||checkType.toLowerCase()=="song"))
           ? downloadUrl : undefined}
          
          download={title}
          ref={downloadRef} 
          onClick={(e) => {
            if (!downloadUrl) {
              e.preventDefault(); // Prevent download if URL is not set
              saveDownloadHistory(); // Trigger API and set URL if successful
            }
          }}
        >
          {token && (

            <img
              src={musicDownload}
              className="music-icon-size"
              // onClick={saveDownloadHistory}
            />
          )}
        </a>
      );
    }
  };

  const downloadElse = () => {
    downloadType = (
      <div>
        <img
          src={musicDownload}
          onClick={() => {
            setOpen(true);
            setDialogText((userType.includes("listener")||userType.includes("listener_plus")) && token ? "3" : "2");
          }}
          className="music-icon-size"
        />
      </div>
    );
  };

  switch (true) {
    case checkType == "Songs" || checkType == "Song":
      isDownload();
      break;
    case userType !== "listener"|| userType !== "listener_plus":
      isDownload();
      break;
    default:
      downloadElse();
  }

  const hoverAudioCancel = () => {
    setCloseAudio(true);
  };

  const hoverAudioLeave = () => {
    setCloseAudio(false);
  };

  const closeAudioPlayer = () => {
    removeAudioPlayer();
  };

  const loginAlertFun = () => {
    return (
      <>
        {open && (
          <LoginAlert
            open={open}
            handleClose={handleClose}
            dialogText={dialogText}
          />
        )}

        {openAlertLogin && (
          <LoginAlert
            open={openAlertLogin}
            handleClose={handleCloseAlertLogin}
            dialogText={dialogTextAlertLogin}
          />
        )}
      </>
    );
  };


 
  

  return (
    <>
      <div
        className="mainAudioPlayer"
        style={{ position: "relative" }}
        onMouseMove={hoverAudioCancel}
        onMouseLeave={hoverAudioLeave}
        data-test-id="mainAudioPlayer"
      >
        <div>
          <AudioPlayerFun
            track_file={track_file}
            songSrc={songSrc}
            audioPlayer={audioPlayer}
            onPlaying={onPlaying}
          />

          <Hidden smDown>
            <Grid container xs={12}>
              <SmMobileView title={title} artist_name={artist_name} />

              <CustomAudioPlayerCoverImage
                checkType={checkType}
                playlistData={playlistData}
                attributes={attributes}
                artist_name={artist_name}
                title={title}
                loopSys={loopSys}
                AudioLoop={AudioLoop}
                toPrevTrack={toPrevTrack}
                playList={playList}
                loader={audioLoader}
                finishCheck={finishCheck}
                restart={restart}
                IconColor={IconColor}
                playBt={playBt}
                pause={pause}
                play={play}
                StartTimeCalc={StartTimeCalc}
                nextOrder={nextOrder}
                seekBar={seekBar}
                seekValue={seekValue}
                endingTime={endingTime}
                endTime={endTime}
                token={token}
                downloadType={downloadType}
                sound={sound}
                handleAddToPlaylist={() => handleAddToPlaylist(history,playlistData, playList,checkType,playlistQueue)}
                soundOn={soundOn}
                soundOff={soundOff}
                volumeChange={volumeChange}
                setHandleAudioVideo={setHandleAudioVideo}
                nextTrack={nextTrack}
                playListwholeList={wholeList}
                countUpdate={updatedCountData}
                trackTypeData={updatedTrack}
                disableText={updateDisable}
                countData={countData}
                currentindex = {Object.keys(wholeList).length>0 && wholeList.findIndex(
                  (item: any) => item.id === nextTrack?.id)}
              />
            </Grid>
          </Hidden>

          <Hidden only={["xs"]} mdUp>
            <Slider
              className="seek-bar"
              onChange={seekBar}
              value={seekValue}
              trackStyle={{ backgroundColor: "blue" }}
              tooltip={{
                open: false,
              }}
              style={{ margin: "0px 0px -4px 0px" }}
            />
            <Grid container item xs={12} className="audio-player">
              <CustomAudioPlayerCenterIcons
                token={token}
                checkType={checkType}
                playlistData={playlistData}
                attributes={attributes}
                title={title}
                artist_name={artist_name}
                loopSys={loopSys}
                AudioLoop={AudioLoop}
                toPrevTrack={toPrevTrack}
                loader={audioLoader}
                finishCheck={finishCheck}
                restart={restart}
                IconColor={IconColor}
                playBt={playBt}
                downloadType={downloadType}
                pause={pause}
                nextOrder={nextOrder}
                play={play}
                handleAddToPlaylist={() => handleAddToPlaylist(history,playlistData, playList,checkType,playlistQueue)}
                nextTrack={nextTrack}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <CustomAudioPlayerRightIcons
              seekBar={seekBar}
              seekValue={seekValue}
              checkType={checkType}
              playlistData={playlistData}
              attributes={attributes}
              title={title}
              artist_name={artist_name}
              loader={audioLoader}
              finishCheck={finishCheck}
              restart={restart}
              IconColor={IconColor}
              playBt={playBt}
              pause={pause}
              play={play}
              nextTrack={nextTrack}
            />
          </Hidden>
        </div>
        {loginAlertFun()}
        
        <CustomAudioCloseLogin
          closeAudio={closeAudio}
          open={open}
          handleClose={handleClose}
          dialogText={dialogText}
          closeAudioPlayer={closeAudioPlayer}
        />
      </div>
    </>
  );
};

export default React.memo(CustomAudioPlayer);
// Customizable Area End