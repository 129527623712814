// Customizable Area Start
import React from "react"
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createBrowserHistory } from "history";
import { HISTORY } from "../../../components/src/common";

// Customizable Area End

export const configJSON = require("./config");
const history = createBrowserHistory()
export interface Props {
    navigation: any;
    id: string;

}
interface S {
    dashboardData: any;
    errorMsg: string;
    loading: boolean;
    banner: any;
    creativeList: any;
    apiToken: any;
    originalList: any,
    bannerData: any;
    originalId: any;
    trendingList: any;
    releaseList: any;
    selected: any;
    handleAudioVideo1: any
    bannerOption1:any;
    bannerTrack1:any;
    vedioAdsData: any;
}
interface SS {
    id: any;
}

export default class VideoPageController extends BlockComponent<Props, S, SS> {
    dashboardApiCallId: string = "";
    apiGetQueryStrinurl: string = "";
    gethandlepreviewlist: string = ""
    orginalRef: any;
    getplaylists: any;
    getoriginallists: any;
    getalloriginallists: any;
    getcreativedataList: any;
    getbannerList: any
    getTrendinglists: any;
    getReleaseList: any;
    trendingRef: any;
    newReleseRef: any;
    vidRef: any;
    getVideoDisplayApiCall: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.trendingRef = React.createRef();
        this.vidRef = React.createRef();
        this.newReleseRef = React.createRef()
        console.disableYellowBox = true;
        this.orginalRef = React.createRef();


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            dashboardData: [],
            errorMsg: "",
            loading: false,
            banner: [],
            creativeList: [],
            originalList: [],
            apiToken: localStorage.getItem("token"),
            originalId: 0,
            bannerData: [],
            trendingList: [],
            releaseList: [],
            selected: null,
            handleAudioVideo1: 0,
            bannerOption1:"",
            bannerTrack1:"",
            vedioAdsData: []

        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        localStorage.removeItem("selectedTab")
        localStorage.removeItem("selectedSingleVideo");
        this.getOriginalID()
        this.getBannerList();
        this.getTrendingLists();
        this.getReleaseLists();
        this.getCreativeLists(this.state.apiToken)
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message)

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));


        switch (apiRequestCallId) {
            case this.gethandlepreviewlist:
                this.handlePreviewList(responseJson)

                break;
            case this.getcreativedataList:
                this.setDataBasedOnCreatives(responseJson);
                break;
            case this.getTrendinglists:
                this.setDataBasedOnTrending(responseJson);
                break;
            case this.getReleaseList:
                this.setDataBasedOnRelease(responseJson);
                break;
            case this.getoriginallists:
                this.setDataBasedOnAllOriginal(responseJson);
                break;
            case this.getbannerList:
                this.setDataBasedOnBannerList(responseJson)
                break;
            case this.getalloriginallists:
                this.setDataBasedOnAllOriginalList(responseJson);
                break;
            case this.getVideoDisplayApiCall:
                this.getVideoDisplaySuccess(responseJson);
                break;
            default:
                break;
        }
    }


    getToken = () => {
        const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
        this.send(msg);
    }

    

    getBannerList = async () => {
        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: this.state.apiToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getbannerList = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getbannerdata}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    handleBannerPreview = async (options: any, id: any, track: any) => {
        this.setState({bannerOption1:options,bannerTrack1:track})
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.gethandlepreviewlist = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.gethandlepreview}?id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        
       
    }

    getCreativeLists = async (token: any) => {
        this.setState({ loading: true });
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getcreativedataList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getcreativedata}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
       
    }



    //play lists
    getOriginalID = async () => {
        // try {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem('token'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getoriginallists = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.baseUrl + `${configJSON.getoriginaldata}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    getallOriginalLists = async (id: any) => {
        this.setState({ loading: true });

            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getalloriginallists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getalloriginallist}?upload_type=Video&user_id=${id}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        

    }

    redirectToOriginals = () => {
        localStorage.removeItem("selectedTab")
        const id: any = localStorage.getItem("user_id")
        localStorage.setItem("temp_id", id)
        localStorage.removeItem("user_id")
        localStorage.setItem("user_id", this.state.originalId)
        localStorage.setItem("creatives_screen", "true")
        // await localStorage.removeItem("current_type")
        const user = "original";
        localStorage.setItem("current_type", user);
        HISTORY.push(`/originals/videos?originals_id=${this.state.originalId}`)
    }

    getTrendingLists = async () => {
        localStorage.removeItem("selectedSingleVideo");
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: localStorage.getItem('token'),
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getTrendinglists = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getTrendingList}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        

    }
    getReleaseLists = async () => {
        localStorage.removeItem("selectedSingleVideo");
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: this.state.apiToken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getReleaseList = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.baseUrl + `${configJSON.getReleaseList}`
            );


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.getAPIMethod
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        

    }

    setDataBasedOnCreatives = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.errors) {
            this.setState({
                creativeList: responseJson?.data,
                loading: false
            });
        } else {
            this.setState({ loading: false });
        }
    }

    setDataBasedOnTrending = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message ) {
            this.setState({
                trendingList: responseJson?.data,
            });
            this.getDisplayVideoAd();
        }
    }


    setDataBasedOnRelease = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                releaseList: responseJson?.data
            });
        }
    }



    setDataBasedOnAllOriginal = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ originalId: responseJson?.data?.id })
            this.getallOriginalLists(responseJson?.data?.id)

        } else {
            this.setState({ loading: false })
        }
    }

    setDataBasedOnAllOriginalList = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.error  && !responseJson.message) {
            this.setState({
                originalList: responseJson?.data,
                loading: false
            });
        } else {
            this.setState({ loading: false })
        }
    }

    setDataBasedOnBannerList = (responseJson: any) => {
        if (responseJson !== undefined && !responseJson.error && !responseJson.message) {
            this.setState({
                banner: responseJson.data,
                loading: false
            });
        } else {
            this.setState({ loading: false })
        }
    }

    handlePreviewList=(responseJson:any)=>{
        
        if (responseJson !== undefined && !responseJson.error) {
            this.setState({ bannerData: responseJson?.data })
            if (this.state.bannerOption1 == "Watch") {
                HISTORY.push({
                    pathname: "/home/Charts/videos",
                    state: { video_list: this.state.bannerTrack1, activeNo: "5", data: responseJson?.data, currentList: this.state.banner ? this.state.banner : [] }
                })
            } else {
                HISTORY.push({
                    pathname: "/home/expand/song/songId=banner",
                    state: {
                        data: responseJson?.data,
    
                    }
                })
            }
        }
    }

    getVideoDisplaySuccess = (response: any) => {
        if (response !== undefined && !response.error && !response.message) {
            const transformedData = response.data.map((item: { attributes: { id: any; }; }) => ({
                ...item,
                id: item.attributes.id
              }))
                this.setState(prevState=>({ 
                    trendingList: 
                  [...transformedData,...prevState.trendingList ],
                }))  
        }
    }

    getDisplayVideoAd = async () => {

        const header = {
            "Content-Type": "application/json",
            token: this.state.apiToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVideoDisplayApiCall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDisplayVideo
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

}
// Customizable Area End